import React, { Component, useEffect } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';
import Register from './components/Register/Register';
import login from './components/Login/Login';
import i18n from './locale';
import { withTranslation } from 'react-i18next';
import './custom.css';
import cookie from 'js-cookie';

export class App extends Component {
  static displayName = App.name;
  render() {
    return (
      <Layout>

        <Route exact path='/' component={Home} />
        <Route path='/counter' component={Counter} />
        <Route path='/fetch-data' component={FetchData} />
        <Route path='/register' component={Register} />
        <Route path='/login' component={login} />
      </Layout>
    );
  }
}
const languages = [{
  code: 'en',
  name_c: 'English',
  countery_code: 'gb',
},
{
  code: 'fa',
  name_c: 'Persian',
  countery_code: 'IR',
  dir: 'rtl',
},
]
function Apptwo({ t }) {
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  }
  const currentLanguageCode = cookie.get('i18next') || 'en';
  const currentLanguage = languages.find(l => l.code === currentLanguageCode);
  
  useEffect(() => {
    document.body.dir = currentLanguage.dir || 'ltr'
  }, [currentLanguage])

  function refreshPage() {
    window.location.reload(false);
  }

  return (
    <div>
      <button onClick={() => {refreshPage(); changeLanguage('en');}}>En</button>
      <button onClick={() => {refreshPage(); changeLanguage('fa');}}>Fa</button>
      <Route path='/login' component={login} />
      <Route path='/register' component={Register} />
    </div>
  )
}

export default withTranslation()(Apptwo);
import React, { Component, Fragment } from "react";
import {
  AppBar,
  Button,
  Toolbar,
  Typography
} from "@material-ui/core";
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
//RTL
import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
//Style
import { Trans, withTranslation } from 'react-i18next';
//import { LanguageVariant } from "typescript";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://telesiness.ir/">
        Telesiness
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
export class FormUserDetails extends Component {
  continue = e => {
    e.preventDefault();
    this.props.nextStep();
  };
  constructor(props) {
    super(props);
    this.state = {
      value:'',
      category: [],
      post: {
        author: "",
        title: "",
        blogText: "",
        dateTime: "",
        categoryId: ""
      }
    };
    this.onChange = this.onChange.bind(this);
  }


  handleOnChange(key, e) {
    console.log(e.target.value);
    let post = this.state.post;
    post[key] = e.target.value; // key variable can be a string
    console.log(this.state);
    const { name, value } = e.target;

    this.setState({
      [name]: value
    });
  }
  formSubmit(event) {
    event.preventDefault();
    console.log(this.state.selectedOption)
  }

    onChange(e) {
    this.setState({ value: e.target.value });
  }
  render() {
    const { t } = withTranslation(
      'Please select your occupation group', 'Lawyer', 'Customer', 'Agent', 'Continue',
    );
    return (
      <StylesProvider jss={jss}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className="card shadow-sm border-0 px-3 rounded-2 mb-3 py-4 mx-auto mt-5 bg-light">
            <div className="card-body">
              <Fragment>
                <AppBar style={{ background: "#2E3B55" }} position="sticky">
                  <Toolbar title="Enter User Details">
                    <Grid item xs={12} container justify="center">
                      <Typography color="inherit" variant="title">
                        <Trans t={t}>Please select your occupation group</Trans>
                      </Typography>
                    </Grid>
                  </Toolbar>
                </AppBar>
              </Fragment>
              <Grid item xs={12} container justify="center">
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="rdoLawyer" id="flexRadioLawyer" onChange={this.onChange}/>
                  <label className="form-check-label" for="flexRadioLawyer">
                    <Trans t={t}>Lawyer</Trans>
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="rdoCustomer" id="flexRadioCustomer" onChange={this.handleChange} disabled />
                  <label className="form-check-label" for="flexRadioCustomer">
                    <Trans t={t}>Customer</Trans>
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="rdoAgent" id="flexRadioAgent" onChange={this.handleChange} disabled />
                  <label className="form-check-label" for="flexRadioAgent">
                    <Trans t={t}>Agent</Trans>
                  </label>
                </div>
                {/* <select onChange={e => this.handleOnChange('categoryId', e)}>
                    <option value="1">Lawyer</option>
                  </select> */}
              </Grid>
              <br />
              <Grid item xs={12} container justify="center">
                <Button
                disabled={!this.state.value}
                  style={{ background: "#2E3B55", color: "#FFFFFF" }}
                  label="Continue"
                  onClick={this.continue}
                >
                  <Trans t={t}>Continue</Trans>
                </Button>
              </Grid>
            </div>
          </div>
          <Box mt={8}>
            <Copyright />
          </Box>
        </Container>
      </StylesProvider >
    );
  }
}

export default FormUserDetails;

import React, { Component } from "react";
//import { render } from "react-dom";
import './register.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { div } from 'react-bootstrap';
import { withStyles } from "@material-ui/core/styles";
import { register } from "./RegistrationStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import { Input, Button } from "@material-ui/core";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
import ErrorIcon from "@material-ui/icons/Error";
import VisibilityTwoToneIcon from "@material-ui/icons/VisibilityTwoTone";
import VisibilityOffTwoToneIcon from "@material-ui/icons/VisibilityOffTwoTone";
import CloseIcon from "@material-ui/icons/Close";
//---------------------------------------------------
//import moment from "moment-timezone";
import DatePicker from "./DatePicker";
import DateFnsUtils from '@date-io/date-fns';
//import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
//----------------------------------------------------
import { Trans, withTranslation } from 'react-i18next';
//----------------------------------------------------
import Select from '../Countery/CountrySelector';
//---------------------------------------------------------------
import uploader from '../Uploader/uploader';
//---------------------------------------------------------------
class Registration extends Component {
  state = {
    fullname:"",
    email: "",
    password: "",
    passwordConfrim: "",
    hidePassword: true,
    error: null,
    errorOpen: false
  };
  //--------------------Insert certificate------------------------------------------
  state = {
    rows: [{}]
  };
  handleChangeCertificate = idx => e => {
    const { name, value } = e.target;
    const rows = [...this.state.rows];
    rows[idx] = {
      [name]: value
    };
    this.setState({
      rows
    });
  };
  handleAddRow = () => {
    const item = {
      name: ""
    };
    this.setState({
      rows: [...this.state.rows, item]
    });
  };
  // handleRemoveRow = () => {
  //   this.setState({
  //     rows: this.state.rows.slice(0, -1)
  //   });
  // };
  handleRemoveSpecificRow = (idx) => () => {
    const rows = [...this.state.rows]
    rows.splice(idx, 1)
    this.setState({ rows })
  }
  //----------------------------------- End of insert certificate ---------------------------------------
  //----------------------------------- Start upload component -----------------------------------------
  componentDidMount() {
    const dropArea = document.getElementById("drop-area");
    ["dragenter", "dragover", "dragleave", "drop"].forEach(eventName => {
      dropArea.addEventListener(eventName, this.preventDefaults, false);
    });

    ["dragenter", "dragover"].forEach(eventName => {
      dropArea.addEventListener(eventName, this.highlight, false);
    });

    ["dragleave", "drop"].forEach(eventName => {
      dropArea.addEventListener(eventName, this.unHightLight, false);
    });

    dropArea.addEventListener("drop", this.handleDrop, false);
  }
  componentWillUnmount() {
    const dropArea = document.getElementById("drop-area");
    ["dragenter", "dragover", "dragleave", "drop"].forEach(eventName => {
      dropArea.removeEventListener(eventName, this.preventDefaults, false);
    });

    ["dragenter", "dragover"].forEach(eventName => {
      dropArea.removeEventListener(eventName, this.highlight, false);
    });

    ["dragleave", "drop"].forEach(eventName => {
      dropArea.removeEventListener(eventName, this.unHightLight, false);
    });

    dropArea.removeEventListener("drop", this.handleDrop, false);
  }
  preventDefaults = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  highlight = () => {
    const ele = document.querySelector(".upload-label");
    if (ele) {
      ele.style.backgroundColor = "#e9e9e9";
      ele.style.border = "2px dotted #999";
    }
  };

  unHightLight = () => {
    const ele = document.querySelector(".upload-label");
    if (ele) {
      ele.style.backgroundColor = "#f6f6f6";
      ele.style.border = "unset";
    }
  };

  handleDrop = e => {
    const dt = e.dataTransfer;
    const { files } = dt;
    this.handleFiles(files);
  };

  handleFiles = files => {
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onloadend = function () {
      let img = document.createElement("img");
      img.src = reader.result;
      img.className = "image";
      document.getElementById("drop-area").appendChild(img);
    };
  };

  //----------------------------------- End upload component ------------------------------------------
  errorClose = e => {
    this.setState({
      errorOpen: false
    });
  };

  handleChange = emailname => e => {
    this.setState({
      [emailname]: e.target.value
    });
    let errors = {};
    let t = withTranslation();
    if (this.state.email === "") {
      errors.email = <Trans t={t}>Email is required</Trans>;
    } else if (!/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(this.state.email)) {
      errors.email = <Trans t={t}>Email isn't valid</Trans>;
    }
    return errors;
  };

  passwordMatch = () => this.state.password === this.state.passwordConfrim;

  showPassword = () => {
    this.setState(prevState => ({ hidePassword: !prevState.hidePassword }));
  };

  isValid = () => {
    let errors = {};
    let t = withTranslation(
      'Full Name');
    if (this.state.email === "") {
      errors.email = <Trans t={t}>Email is required</Trans>;
    } else if (!/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(this.state.email)) {
      errors.email = <Trans t={t}>Email isn't valid</Trans>;
    }
    return errors;
  };
  submitRegistration = e => {
    e.preventDefault();
    if (!this.passwordMatch()) {
      this.setState({
        errorOpen: true,
        error: "Passwords don't match"
      });
    }

    const newUserCredentials = {
      fullname:this.state.fullname,
      email: this.state.email,
      password: this.state.password,
      passwordConfrim: this.state.passwordConfrim
    };
    console.log("this.props.newUserCredentials", newUserCredentials);
    //dispath to userActions
  };
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     controlledDate: moment(`2014-06-01T12:00:00Z`).utc().tz('America/Los_Angeles').toDate(),
  //   };
  //   console.log(this.state.controlledDate)
  // }

  handledateChange = (event, date) => {
    this.setState({
      controlledDate: date,
    });
  };

  render() {
    const { classes } = this.props;
    const { t } = withTranslation(
      'Full name', 'Date of birth',
    );

    return (
      <div className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <PeopleAltIcon className={classes.icon} />
          </Avatar>
          <form
            className={classes.form}
            onSubmit={() => this.submitRegistration}
          >
            <div className="form-group row">
              <div className="col-4">
                <label for="inputdefault" htmlfor="fullname"><Trans t={t}>Full name</Trans>: <span className="text-danger">*</span></label>
                <Input type="text" name="fullname" 
                className="form-control" 
                aria-describedby="inputGroup-sizing-sm" 
                required
                />
              </div>
              <div className="col-4">
                <label className="form-label"><Trans t={t}>Date of birth</Trans>: <span className="text-danger">*</span></label>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker />
                </MuiPickersUtilsProvider>
              </div>
              <div className="col-4">
                <label for="inputdefault"><Trans t={t}>Level/Degree of education</Trans>:</label>
                <Input 
                type="text"         
                 className="form-control" 
                 aria-describedby="inputGroup-sizing-sm" 
                 />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-4">
                <label className="form-label"><Trans t={t}>Field of study</Trans>:</label>
                <Input type="text" className="form-control" aria-describedby="inputGroup-sizing-sm" />
              </div>
              <div className="col-4">
                <label className="form-label"><Trans t={t}>Institute/University</Trans>:</label>
                <Input type="text" className="form-control" aria-describedby="inputGroup-sizing-sm" />
              </div>
            </div>
            <br />
            <div className="form-group row">
              <div className="col-12">
                <div className="row clearfix">
                  <div className="col-md-12 column">
                    <table
                      className="table table-bordered table-hover"
                      id="tab_logic"
                    >
                      <thead>
                        <tr>
                          <th className="text-center"><Trans t={t}>Certificates</Trans></th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.rows.map((item, idx) => (
                          <tr id="addr0" key={idx}>
                            <td>
                              <input
                                type="text"
                                name="name"
                                value={this.state.rows[idx].name}
                                onChange={this.handleChangeCertificate(idx)}
                                className="form-control"
                              />
                            </td>
                            <td>
                              <button
                                className="btn btn-outline-danger btn-sm"
                                onClick={this.handleRemoveSpecificRow(idx)}
                              >
                                <Trans t={t}>Remove</Trans>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <button onClick={this.handleAddRow} className="btn btn-primary">
                      <Trans t={t}>Add</Trans>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-12">
                <label className="form-label"><Trans t={t}>Job specialty</Trans>:</label>
                <textarea class="form-control" aria-label="With textarea"></textarea>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-12">
                <label className="form-label"><Trans t={t}>Specialization in immigration</Trans>:</label>
                <textarea class="form-control" aria-label="With textarea"></textarea>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-4">
                <label className="form-label"><Trans t={t}>Email</Trans>: <span className="text-danger">*</span></label>
                <Input type="text" className="form-control" aria-describedby="inputGroup-sizing-sm" required/>
              </div>
              <div className="col-8">
                <label className="form-label"><Trans t={t}>Country of your activity</Trans>: <span className="text-danger">*</span></label>
                <Select />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-4">
                <label className="form-label"><Trans t={t}>City of your activity</Trans>: <span className="text-danger">*</span></label>
                <Input type="text" className="form-control" aria-describedby="inputGroup-sizing-sm" required />
              </div>
              <div className="col-4">
                <label className="form-label"><Trans t={t}>Phone number</Trans>: <span className="text-danger">*</span></label>
                <Input type="number" className="form-control" aria-describedby="inputGroup-sizing-sm" required/>
              </div>
              <div className="col-4">
                <label className="form-label"><Trans t={t}>Personal or company website</Trans>:</label>
                <Input type="text" className="form-control" aria-describedby="inputGroup-sizing-sm" />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-4">
                <label className="form-label"><Trans t={t}>Law society number/iccrc code</Trans>: <span className="text-danger">*</span></label>
                <Input type="number" className="form-control" aria-describedby="inputGroup-sizing-sm" required/>
              </div>
              <div className="col-8">
                <label className="form-label"><Trans t={t}>Resume/CV</Trans>: <span className="text-danger">*</span></label>
                <div id="drop-area">
                  <input
                    type="file"
                    id="fileElem"
                    accept="pdf/*"
                    onChange={e => {
                      this.handleFiles(e.target.files);
                    }}
                    required
                  />
                  <label className="upload-label" htmlFor="fileElem">
                    <div className="upload-text"><Trans t={t}>Drag your CV here or click to upload</Trans></div>
                  </label>
                  <uploader form />
                  {/* <div className="image" /> */}
                </div>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-12">
                <label className="form-label"><Trans t={t}>About yourself</Trans>:</label>
                <textarea class="form-control" aria-label="With textarea"></textarea>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-4">
                <label className="form-label"><Trans t={t}>Username</Trans>: <span className="text-danger">*</span></label>
                <Input type="text" className="form-control" aria-describedby="inputGroup-sizing-sm" required />
              </div>
              <div className="col-4">
              <label className="form-label"><Trans t={t}>Password</Trans>: <span className="text-danger">*</span></label>
              <Input
              required
                name="password"
                autoComplete="password"
                className={classes.inputs}
                disableUnderline={true}
                onChange={this.handleChange("password")}
                type={this.state.hidePassword ? "password" : "input"}
                endAdornment={
                  this.state.hidePassword ? (
                    <InputAdornment position="end">
                      <VisibilityOffTwoToneIcon
                        fontSize="default"
                        className={classes.passwordEye}
                        onClick={this.showPassword}
                      />
                    </InputAdornment>
                  ) : (
                    <InputAdornment position="end">
                      <VisibilityTwoToneIcon
                        fontSize="default"
                        className={classes.passwordEye}
                        onClick={this.showPassword}
                      />
                    </InputAdornment>
                  )
                }
              />
                </div>
                <div className="col-4">
                <label className="form-label"><Trans t={t}>Re-Type Password</Trans>: <span className="text-danger">*</span></label>
              <Input
              required
                name="passwordConfrim"
                autoComplete="passwordConfrim"
                className={classes.inputs}
                disableUnderline={true}
                onClick={this.state.showPassword}
                onChange={this.handleChange("passwordConfrim")}
                type={this.state.hidePassword ? "password" : "input"}
                endAdornment={
                  this.state.hidePassword ? (
                    <InputAdornment position="end">
                      <VisibilityOffTwoToneIcon
                        fontSize="default"
                        className={classes.passwordEye}
                        onClick={this.showPassword}
                      />
                    </InputAdornment>
                  ) : (
                    <InputAdornment position="end">
                      <VisibilityTwoToneIcon
                        fontSize="default"
                        className={classes.passwordEye}
                        onClick={this.showPassword}
                      />
                    </InputAdornment>
                  )
                }
              />
                  </div>
            </div>
            <Button
              disabled={!this.isValid()}
              disableRipple
              fullWidth
              variant="outlined"
              className={classes.button}
              type="submit"
              onClick={this.submitRegistration}
            >
              <Trans t={t}>Sign up</Trans>
            </Button>
          </form>

          {this.state.error ? (
            <Snackbar
              variant="error"
              key={this.state.error}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
              }}
              open={this.state.errorOpen}
              onClose={this.errorClose}
              autoHideDuration={3000}
            >
              <SnackbarContent
                className={classes.error}
                message={
                  <div>
                    <span style={{ marginRight: "8px" }}>
                      <ErrorIcon fontSize="large" color="error" />
                    </span>
                    <span> {this.state.error} </span>
                  </div>
                }
                action={[
                  <IconButton
                    key="close"
                    aria-label="close"
                    onClick={this.errorClose}
                  >
                    <CloseIcon color="error" />
                  </IconButton>
                ]}
              />
            </Snackbar>
          ) : null}
        </Paper>
      </div>
    );
  }
}

export default withStyles(register)(Registration);

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
//import HomeLocale from './components/Login/login.locale';
import translationEN from './locales/en/translation.json';
import translationFA from './locales/fa/translation.json';

const resources={
  en:{
    translation:translationEN
  },
  fa:{
    translation:translationFA
  }
};


i18n
.use(LanguageDetector)
.use(HttpApi)
.use(initReactI18next).init({
  supportedLngs:['en','fa'],
  detection:{order: [ 'cookie','htmlTag', 'localStorage','path', 'subdomain'],caches:['cookie'],},
  backend:{
    loadPath:'/locales/{{lang}}/translation.json',
  },
  react:{
    useSuspense:false,
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ["placeholder"],
  },
  resources,
  lang:'en',
  interpolation:{
    escapeValue:false
  }
});
export default i18n;
import React, { useState } from "react";
import ReactDOM from 'react-dom';
import useForm from "./useForm";
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import { Redirect } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import "./login.css";
import image from '../../image/avatar-for-login-form.png';
//RTL
import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
//Style
import './login.css';
import { Trans, withTranslation } from 'react-i18next';
//import { LanguageVariant } from "typescript";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://telesiness.ir/">
        Telesiness
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
function validate(values) {
  let errors = {};
  if (!values.email) {
    errors.email = <Trans t={t}>Email is required</Trans>;
  } else if (!/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(values.email)) {
    errors.email = <Trans t={t}>Email isn't valid</Trans>;
  }
  if (!values.password) {
    errors.password = <Trans t={t}>Password is required</Trans>;
  } 
  return errors;
}
const { t } = withTranslation(
  'Welcome', 'Email', 'Email is requierd',
  'Email is not valid', 'Password',
  'Password is required', 'Remember Me',
  'Sign In', 'Did you forget your Password?', 'Sign up',
);

const Form = props => {
  const { values, errors, handleChange, handleSubmit } = useForm(
    login,
    validate
  );
   // eslint-disable-next-line
  const [setLoggedIn] = useState(false);

  function login() {
    setLoggedIn(true);
    props.parentCallback(true);
    return <Redirect to="/default" />;
  }
 
  return (
    <>
            <StylesProvider jss={jss}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className="card shadow-sm border-0 px-3 rounded-2 mb-3 py-4 mx-auto mt-5 bg-light">
              <div className="card-body">
                <Grid item xs={12} container justify="center">
                  <img src={image} alt="Login Telesiness" className="imgsize" />
                </Grid>
          <Grid item xs={12} container justify="center">
                  <Typography component="h1" variant="h3">
                    <Trans t={t}>Welcome</Trans>
                  </Typography>
                </Grid>
            <form onSubmit={handleSubmit} noValidate>
            <div className="form-group">
                    <label className="mb-0"><Trans t={t}>Email</Trans>: <span className="text-danger">*</span></label>
                <div className="control">
                  <input
                    autoComplete="off"
                    className="form-control"
                    type="email"
                    name="email"
                    onChange={handleChange}
                    value={values.email || ""}
                    required
                  />
                  {errors.email && (
                    <p className="alert alert-danger mt-2">{errors.email}</p>
                  )}
                </div>
              </div>
              <div className="form-group">
                    <label className="mb-0"><Trans t={t}>Password</Trans>:  <span className="text-danger">*</span></label>
                <div className="control">
                  <input
                    className="form-control"
                    type="password"
                    name="password"
                    onChange={handleChange}
                    value={values.password || ""}
                    required
                  />
                </div>
                {errors.password && (
                  <p className="alert alert-danger mt-2">{errors.password}</p>
                )}
              </div>
              <div className="form-check form-check-inline checkboxcenter">
                    <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
                    <label className="form-check-label margincheck" for="inlineCheckbox1"><Trans t={t}>Remember Me</Trans></label>
                  </div>
              <Grid item xs={12} container justify="center">
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className="submitbtn"
              >
               <Trans t={t}>Sign In</Trans>
                    </Button>
                  </Grid>
                  <Grid container>
                    <Grid item>
                      <Link href="#" variant="body2">
                        <Trans t={t}>Did you forgot your Password?</Trans>
                      </Link>
                    </Grid>
                    <Grid item xs={12}>
                      <Link href="./Register" variant="body2">
                        {<Trans t={t}>Sign up</Trans>}
                      </Link>
                    </Grid>
                  </Grid>
            </form>
          </div>
        </div>
    <Box mt={8}>
              <Copyright />
            </Box>
          </Container>
        </StylesProvider>
    </>
  );
};

export default Form;
ReactDOM.render(<login />, document.getElementById('root'))